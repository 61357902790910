<template lang="pug">
div
  div
    div.text-center.mx-auto.mt-11(v-if="dataReady == false || contents == ''")
      v-progress-circular.mt-6(:size="70" :width="7" indeterminate color="primary")
    div.text-center.mx-auto(v-else-if="dataReady == true && contents.length == 0")
      h4.font-weight-light.text-muted.mt-10 No Collections
    div(v-else-if="dataReady == true && contents.length != 0")
      slot(name="content" :contents="contents")
    div.text-lg-end.text-center.mt-3.ma-7(v-if="contents!='' && contents.length!=0 && (prev_btn || next_btn)")
      v-btn(:disabled="!prev_btn" color="primary" @click="previous").mr-6
        v-icon mdi-arrow-left
      v-btn(:disabled="!next_btn" color="primary" @click="next")
        v-icon mdi-arrow-right
</template>
<script>
import { db } from "../main";
export default {
  name: "Paginate",
  props: ["limit", "collection", "order", "field", "equality", "value"],
  data() {
    return {
      contents: "",
      dataReady: false,
      lastVisible: "",
      firstVisible: "",
      next_btn: false,
      prev_btn: false,
    };
  },
  computed: {
    recentBook() {
      return this.$store.state.visibles;
    },
  },
  async created() {
    if (this.recentBook[0] != this.value) {
      await this.$bind(
        "contents",
        db
          .collection(this.collection)
          .orderBy(this.order)
          .where(this.field, this.equality, this.value)
          .limit(this.limit)
      ).then(() => {
        this.dataReady = true;
        if (this.contents.length != 0) {
          db.collection(this.collection)
            .orderBy(this.order)
            .where(this.field, this.equality, this.value)
            .limit(this.limit)
            .get()
            .then((documentSnapshots) => {
              this.lastVisible =
                documentSnapshots.docs[documentSnapshots.docs.length - 1];
              this.firstVisible = documentSnapshots.docs[0];
            });
        }
      });
    } else {
      await this.$bind(
        "contents",
        db
          .collection(this.collection)
          .orderBy(this.order)
          .where(this.field, this.equality, this.value)
          .startAt(this.recentBook[1])
          .limit(this.limit)
      ).then(() => {
        this.dataReady = true;
        if (this.contents.length != 0) {
          db.collection(this.collection)
            .orderBy(this.order)
            .where(this.field, this.equality, this.value)
            .startAt(this.recentBook[1])
            .limit(this.limit)
            .get()
            .then((documentSnapshots) => {
              this.lastVisible =
                documentSnapshots.docs[documentSnapshots.docs.length - 1];
              this.firstVisible = documentSnapshots.docs[0];
            });
        }
      });
    }
    if (this.contents.length != 0) {
      // peep to check if next should be on or off
      db.collection(this.collection)
        .orderBy(this.order)
        .where(this.field, this.equality, this.value)
        .startAfter(this.lastVisible)
        .limit(this.limit)
        .get()
        .then((snap) => {
          if (snap.size === 0) {
            this.next_btn = false;
          } else {
            this.next_btn = true;
          }
          if (this.recentBook[0] == this.value) {
            this.prev_btn = true;
          }
        })
        .catch((error) => {
          console.log("error in loading: ", error);
        });
    }
  },

  methods: {
    next: function () {
      if (this.next_btn) {
        // bind data with books
        this.next_btn = false;
        this.prev_btn = false;
        this.$bind(
          "contents",
          db
            .collection(this.collection)
            .orderBy(this.order)
            .where(this.field, this.equality, this.value)
            .startAfter(this.lastVisible)
            .limit(this.limit)
        )
          .then(() => {
            this.dataReady = true;
          })
          .catch((error) => {
            console.log("error in loading: ", error);
          });
        // set last and first visible items
        db.collection(this.collection)
          .orderBy(this.order)
          .where(this.field, this.equality, this.value)
          .startAfter(this.lastVisible)
          .limit(this.limit)
          .get()
          .then((documentSnapshots) => {
            this.lastVisible =
              documentSnapshots.docs[documentSnapshots.docs.length - 1];
            this.firstVisible = documentSnapshots.docs[0];
            this.$store.dispatch("setVisibles", [
              this.value,
              this.firstVisible,
            ]);
          })
          .then(() => {
            // Peep  on the next  next query to see if it gives zero
            db.collection(this.collection)
              .orderBy(this.order)
              .where(this.field, this.equality, this.value)
              .startAfter(this.lastVisible)
              .limit(this.limit)
              .get()
              .then((snap) => {
                if (snap.size === 0) {
                  //disable button if the next peeped result gets zero
                  this.next_btn = false;
                  // enable previous button
                  this.prev_btn = true;
                } else {
                  // enable next button if peeped result is not zero
                  this.next_btn = true;
                  // enable previous button
                  this.prev_btn = true;
                }
              });
          });
      }
    },
    previous: function () {
      // Ensure previous is not zero
      db.collection(this.collection)
        .orderBy(this.order)
        .where(this.field, this.equality, this.value)
        .endBefore(this.firstVisible)
        .limitToLast(this.limit)
        .get()
        .then((snap) => {
          return snap.size;
        })
        .then((size) => {
          //confirm is not zero here
          if (size !== 0) {
            //bind the previous to books
            this.next_btn = false;
            this.prev_btn = false;
            this.$bind(
              "contents",
              db
                .collection(this.collection)
                .orderBy(this.order)
                .where(this.field, this.equality, this.value)
                .endBefore(this.firstVisible)
                .limitToLast(this.limit)
            );

            // Set last and first visible
            db.collection(this.collection)
              .orderBy(this.order)
              .where(this.field, this.equality, this.value)
              .endBefore(this.firstVisible)
              .limitToLast(this.limit)
              .get()
              .then((documentSnapshots) => {
                this.lastVisible =
                  documentSnapshots.docs[documentSnapshots.docs.length - 1];
                this.firstVisible = documentSnapshots.docs[0];
                this.$store.dispatch("setVisibles", [
                  this.value,
                  this.firstVisible,
                ]);
              })
              .then(() => {
                // peep the next previous query
                db.collection(this.collection)
                  .orderBy(this.order)
                  .where(this.field, this.equality, this.value)
                  .endBefore(this.firstVisible)
                  .limitToLast(this.limit)
                  .get()
                  .then((snap) => {
                    if (snap.size == 0) {
                      //if next peeped previous button gets 0 disable
                      this.prev_btn = false;
                      this.next_btn = true;
                    } else {
                      //if next peeped result is does not get 0 enable buttons
                      this.prev_btn = true;
                      this.next_btn = true;
                    }
                  });
              });
          }
        });
    },
  },
};
</script>
